import React from "react";
import {
  Container,
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  CardMedia,
} from "@mui/material";
import { red, lightBlue } from "@mui/material/colors";
import Image from "mui-image";

import stamp1 from "../../../Assets/stamp1.png";

import stamp2 from "../../../Assets/stamp2.png";

import menuSP from "../../../Assets/dfw-holiday.png";

const HomeDetails = () => {
  const isHolidaySeason = () => {
    const now = new Date();
    const year = now.getFullYear();

    // Calculate the date of Thanksgiving (fourth Thursday of November)
    const getThanksgivingDate = (year) => {
      const novemberFirst = new Date(year, 10, 1); // November 1st
      const dayOfWeek = novemberFirst.getDay(); // 0 (Sun) to 6 (Sat)
      const firstThursday = 1 + ((4 - dayOfWeek + 7) % 7);
      const thanksgivingDate = new Date(year, 10, firstThursday + 21); // Add 3 weeks
      return thanksgivingDate;
    };

    const thanksgivingDate = getThanksgivingDate(year);
    const holidaySeasonStart = thanksgivingDate;
    const holidaySeasonEnd = new Date(year + 1, 0, 1); // January 1st of next year

    return now >= holidaySeasonStart && now < holidaySeasonEnd;
  };

  // Function to get the current season or "HOLIDAY" if during the holiday season
  const getSeason = () => {
    if (isHolidaySeason()) {
      return "HOLIDAY";
    } else {
      const month = new Date().getMonth(); // 0 (Jan) to 11 (Dec)
      if (month >= 2 && month <= 4) {
        return "SPRING";
      } else if (month >= 5 && month <= 7) {
        return "SUMMER";
      } else if (month >= 8 && month <= 10) {
        return "AUTUMN";
      } else {
        return "WINTER";
      }
    }
  };

  const currentSeason = getSeason(); // Get the current season
  return (
    <Box>
      <Box
        sx={{
          py: 3,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        <Card
          sx={{
            width: { md: "95%", lg: "40%" },
            backgroundImage: `url("https://www.transparenttextures.com/patterns/retina-wood.png")`,
          }}
        >
          <CardMedia
            component="img"
            sx={{
              height: { xs: "auto", md: "auto" },
              objectFit: { xs: "fill", md: "contain" },
              border: "5px solid #ffff",
              borderRadius: "10px",
            }}
            image={menuSP}
          />
        </Card>
      </Box>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: lightBlue[600],
        }}
      >
        <Typography variant="h3">
          Get Your BBQ Order Here: <br />
          682-262-7553
        </Typography>
        <Typography variant="h3" sx={{ padding: "0 10%", lineHeight: "" }}>
          {currentSeason} SEASON
          <br /> Is Here..! <br />
          Let's BBQ!!
        </Typography>

        {/* <Typography sx={{ padding: "0 30%" }}>
          Fine Authentic Texas BBQ Holiday Meals are available with 48hrs
          advance notice.
        </Typography> */}
        <Button
          size="large"
          color="inherit"
          sx={{ width: "35%" }}
          variant="outlined"
          href="/menu"
        >
          <Typography variant="h6">View Menu</Typography>
        </Button>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "40px",
          textAlign: "center",
          alignItems: "center",
          padding: 3,
          backgroundColor: red[900],
        }}
      >
        <Typography variant="h3">
          <Image
            duration="0"
            src={stamp1}
            style={{ height: "250px", width: "300px" }}
          />
          AUTHENTIC PREMIUM TEXAS BBQ <br /> TRUE TEXAS FLAVOR
        </Typography>

        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          Our Premium BBQ is delicious tasting and we crafted it with love
          <br /> We use premium meats and fine herbs and spices. Our meats are
          slowed cooked to perfection in-house over natural Texas hickory wood.
          <br />
          All of our catering comes with choices of sides made by our team of
          chefs. We offer coleslaw, homestyle potato salad, mashed potatoes,
          green beans, mac n cheese, scalloped potatoes and jalapenos pinto
          beans. Our desserts are yummy as well with various pies selections and
          more. Catering can be picked up or delivered to your location. Holler
          Valerie and get a quote.
          <br />
        </Typography>
        <Typography fontFamily="roboto" sx={{ px: "20%" }} variant="p">
          Best BBQ Brisket! Best Pulled Pork! Best Smoked Turkey! Best Sausage!
          Best Smoked Whole Chicken! Best Smoked BBQ Pork Ribs! Best Smoked BBQ
          Beef Ribs!
        </Typography>

        <Typography variant="h6">Oh So So Good! God Help Us All!</Typography>
      </Container>
      <Container
        maxWidth="100%"
        sx={{
          border: "0 2px solid",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: "50px",
          textAlign: "center",
          alignItems: "center",
          padding: "30px 0",
          backgroundColor: lightBlue[600],
        }}
      >
        <Typography variant="h3">
          <Image
            duration="0"
            src={stamp2}
            style={{ height: "280px", width: "300pxx" }}
          />
          Slow Smoke Hickory TX Wood <br />
          Enough Said
        </Typography>
        <Typography fontFamily="roboto" sx={{ padding: "0 20%" }} variant="p">
          Servin' the DFW Metroplex for over 40yrs. The Austin family loves
          smokin' Texas Style BBQ using Premium Meats and Fine Herbs & Spices.
          <br />
          Award-winning BBQ Briskets, our very own Robert Austin, PitMaster,
          crafts his skill and passion for Smoked BBQ for your enjoyment.
          <br />
          Holler Us now, Valerie Austin is ready to cater your events or
          parties.
        </Typography>
        <Typography sx={{ padding: "0 20%" }} variant="h6">
          What cha waitin' for!
        </Typography>

        <Button
          size="large"
          color="inherit"
          sx={{
            width: "25%",
          }}
          variant="outlined"
          href="/menu"
        >
          <Typography variant="h6">View Menu</Typography>
        </Button>
      </Container>
    </Box>
  );
};

export default HomeDetails;
